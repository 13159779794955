import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
// import SearchBox from '../SearchBox';

const NavBar = ({ toggleNavbar, isActive }) => (
  <StaticQuery
    query={graphql`
      query SearchIndexQuery {
        siteSearchIndex {
          index
        }
      }
    `}
    render={(data) => (
      <nav className='navbar is-fixed-top' aria-label='main navigation'>
        <div className='navbar-brand'>
          <Link to='/' className='navbar-item'>
            <strong>Bee Tesla</strong>
          </Link>
          <figure>
            <p>
              <img alt='' src='/img/logo1b.png' width='100' hight='100' />
            </p>
          </figure>
          <button
            className={`button navbar-burger ${isActive ? 'is-active' : ''}`}
            data-target='navMenu'
            onClick={toggleNavbar}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <div
          className={`navbar-menu ${isActive ? 'is-active' : ''}`}
          id='navMenu'
        >
          <div className='navbar-end'>
            {/* <SearchBox searchIndex={data.siteSearchIndex.index} /> */}
            <Link className='navbar-item is-dark' to='/about'>
              O nama
            </Link>
            <Link className='navbar-item' to='/kursevi-programiranja'>
              Kursevi
            </Link>
            <Link className='navbar-item' to='/blog'>
              Vesti
            </Link>
            <Link className='navbar-item' to='/materijal'>
              Materijal
            </Link>
            {/* <Link className='navbar-item' to='/letnja-skola-programiranja'>
              Letnja škola
            </Link> */}
            <Link className='navbar-item' to='/galerija'>
              Galerija
            </Link>
            <div className='navbar-item'>
              <div className='field is-grouped'>
                <p className='control'>
                  <Link className='button is-primary is-outlined' to='/contact'>
                    Kontakt
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )}
  />
);

export default NavBar;
