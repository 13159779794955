import React, { useState } from 'react';
import Helmet from 'react-helmet';
import '../../assets/sass/styles.sass';
import config from '../../../config';
import NavBar from '../NavBar';
import Footer from '../Footer';

const Layout = (props) => {
  const [isActive, setIsActive] = useState(false);
  const toggleNavbar = () => {
    setIsActive(!isActive);
  };

  return (
    <div id='layout-wrapper'>
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta name='description' content={config.siteDescription} />
      </Helmet>

      <NavBar isActive={isActive} toggleNavbar={toggleNavbar} />

      <div id='content-wrapper'>{props.children}</div>

      <Footer />
    </div>
  );
};

export default Layout;
