import React from 'react';
import config from '../../../config';

const Footer = () => {
  return (
    <footer
      style={{
        // backgroundImage: `url(${Background})`,
        background: `#F7DC5F`,
        height: '15vh',

        marginBottom: `1.45rem`,
      }}
    >
      <div className='container'>
        <div className='content has-text-centered'>
          <p>{config.copyright}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
